import { useCallback, useRef, useState } from 'react';
import {
  isValid,
  openAttestationVerifiers,
  verificationBuilder,
} from '@tradetrust-tt/tt-verify';
import { utils, getData } from '@tradetrust-tt/tradetrust';
import { useDropzone } from 'react-dropzone';
import Loader from './Loader';
import { IframeApp } from './IframeApp';
import InvalidDocument from './InvalidDocument';
import ValidDocument from './ValidDocument';
import DocInfo from './DocInfo';
import cloudUploadImg from './shared/assets/cloud-upload.png';
import checkClipboard from './shared/assets/check-clipboard.png';
import { cn } from '../utils/cn';
import useCurrentChain from '../app/hooks/useChain';
import useAsyncEffect from 'use-async-effect';
import {
  useWeb3Modal,
  useWeb3ModalProvider,
  useWeb3ModalAccount,
  useSwitchNetwork,
} from '@web3modal/ethers5/react';
import clsx from 'clsx';
import { providers, Signer, ethers } from 'ethers';
import { PDFDocument } from 'pdf-lib';

const FileReaderComponent = () => {
  const [loading, setLoading] = useState(false);
  const [isValidIntegrity, setIsValidIntegrity] = useState(false);
  const [isValidDocumentStatus, setIsValidDocumentStatus] = useState(false);
  const [isValidIssuer, setIsValidIssuer] = useState(false);
  const [isValidDoc, setIsValidDoc] = useState(false);
  const [isDocChecked, setIsDocChecked] = useState(false);
  const [issuerLocation, setIssuerLocation] = useState('');
  const [unwrappedData, setUnwrappedData] = useState<any>(null);
  const [encryptedData, setEncryptedDocument] = useState<any>(null);
  const [tokenId, setTokenId] = useState('');
  const [tokenRegistry, setTokenRegistry] = useState('');

  const [signer, setSigner] = useState<Signer | null>(null);

  const { currentChain } = useCurrentChain();
  const { open } = useWeb3Modal();

  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { switchNetwork } = useSwitchNetwork();
  //console.log('chainId: ', chainId);
  const { walletProvider } = useWeb3ModalProvider();
  const providerRef = useRef<providers.Provider | null>(null);

  useAsyncEffect(async () => {
    if (walletProvider) {
      const _provider = new ethers.providers.Web3Provider(walletProvider);
      providerRef.current = _provider;
      const _signer = await _provider.getSigner();
      setSigner(_signer);
    }
  }, [chainId]);

  const VerifierOptions: any = {
    20180427: {
      // network: "stability testnet",
      provider: new providers.JsonRpcProvider(
        'https://free.testnet.stabilityprotocol.com',
        20180427
      ),
    },
    101010: {
      // network: "stability mainnet",
      provider: new providers.JsonRpcProvider(
        'https://rpc.stabilityprotocol.com/zgt/0u7eysyltauu',
        101010
      ),
    },
    11155111: {
      provider: new providers.JsonRpcProvider(
        'https://rpc.sepolia.org',
        11155111
      ),
      // network: "sepolia testnet"
    },
    80002: {
      // network: "polygon amoy",
      provider: new providers.JsonRpcProvider(
        'https://rpc-amoy.polygon.technology',
        80002
      ),
    },
    137: {
      // network: "matic",
      provider: new providers.JsonRpcProvider(
        'https://rpc.ankr.com/polygon/9fcd48a40fef9fa8eee3cbffdc50c6c5e509d96a75fe304aa7af92cb7995eb47',
        137
      ),
    },
    295: {
      // network: "hedera",
      provider: new providers.JsonRpcProvider(
        'https://sparkling-thrilling-log.hedera-mainnet.quiknode.pro/7560d4b62b5ae604c5f7b3320a875f8e9cecd74f',
        295
      ),
    },
    50: {
      // network: "xdc",
      provider: new providers.JsonRpcProvider(
        'https://rpc.ankr.com/xdc/9fcd48a40fef9fa8eee3cbffdc50c6c5e509d96a75fe304aa7af92cb7995eb47',
        50
      ),
    },
    1: {
      // network: "ethereum",
      provider: new providers.JsonRpcProvider(
        'https://late-greatest-sunset.quiknode.pro/7c7d9a06b5bdb44bacf8b35aec3eca9ad3cf6517/',
        1
      ),
    },
    84532: {
      // network: "ethereum",
      provider: new providers.JsonRpcProvider(
        'https://base-sepolia.infura.io/v3/44748592263b44b39279e722270965b1',
        84532
      ),
    },
  };
  const onVerify = async (wrappedDocument: any) => {
    try {
      setLoading(true);
      setIsDocChecked(false);

      let _chainId = 137; // Sepolia

      if (utils.isWrappedV2Document(wrappedDocument)) {
        const data = getData(wrappedDocument);
        if (data?.network) {
          _chainId = Number(data?.network?.chainId);
          switchNetwork(_chainId);
        }
      } else if (utils.isWrappedV3Document(wrappedDocument)) {
        if (wrappedDocument?.network) {
          _chainId = Number(wrappedDocument?.network?.chainId);
          switchNetwork(_chainId);
        }
      }

      //console.log('_provider: ', providerRef.current)
      const verifier = verificationBuilder(
        openAttestationVerifiers,
        VerifierOptions[_chainId]
      );
      const verificationStatus = await verifier(wrappedDocument);
      //console.log(JSON.stringify(verificationStatus, null, 2));

      const isValidR = isValid(verificationStatus);
      console.log('isValid', isValidR);
      const fragments = verificationStatus;

      setIsValidIntegrity(isValid(fragments, ['DOCUMENT_INTEGRITY']));
      setIsValidDocumentStatus(isValid(fragments, ['DOCUMENT_STATUS']));
      setIsValidIssuer(isValid(fragments, ['ISSUER_IDENTITY']));

      if (!isValid(fragments)) {
        setIsValidDoc(false);
        return;
      } else {
        setIsValidDoc(true);
      }

      readDocData(wrappedDocument);
    } catch (error) {
      console.error(error);
      setIsValidDoc(false);
    } finally {
      setIsDocChecked(true);
      setLoading(false);
    }
  };
  const readDocData = async (wrappedDocument: any) => {
    try {
      const unwrapped_data = getData(wrappedDocument);
      setUnwrappedData(unwrapped_data);
      //console.log("unwrapped_data: ", unwrapped_data);
      const issuer: any = unwrapped_data.issuers[0];
      //console.log("issuer: ", issuer);

      const targetHash = wrappedDocument.signature.targetHash;
      //console.log("targetHash: ", targetHash);
      setTokenId('0x' + targetHash);
      setIssuerLocation(issuer.identityProof?.location!);
      setTokenRegistry(issuer.tokenRegistry);
    } catch (error) {
      console.log(error);
    }
  };
  const onDrop = useCallback(async (acceptedFiles: any) => {
    // Do something with the files
    handleReset();
    //console.log(acceptedFiles);
    const file = acceptedFiles[0];

    if (
      file &&
      (file.name.endsWith('.tt') ||
        file.name.endsWith('.pdf') ||
        file.name.endsWith('.json'))
    ) {
      const reader = new FileReader();
      if (file.name.endsWith('.pdf')) {
        const pdfDoc = await PDFDocument.load(await file.arrayBuffer());
        //console.log('Title:', pdfDoc.getTitle())
        //console.log('Author:', pdfDoc.getAuthor())
        //console.log('Subject:', JSON.parse(pdfDoc.getSubject()!))
        //console.log('Creator:', pdfDoc.getCreator())
        onVerify(JSON.parse(pdfDoc.getSubject()!));
        return;
      }

      reader.onload = e => {
        const encryptedDocument = e.target?.result as any;
        setEncryptedDocument(encryptedDocument);
        onVerify(JSON.parse(encryptedDocument));
        //const decryptedData = decryptedDocument(encryptedDocument as any);
      };

      reader.readAsText(file);
    } else {
      alert('Please select a valid .tt file.');
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      'text/html': ['.tt', '.pdf', '.json'],
    },
  });
  const getDocumentErrorMessage = () => {
    //console.log('isValidDocumentStatus:', isValidDocumentStatus);
    //console.log('isValidIntegrity:', isValidIntegrity);
    //console.log('isValidIssuer:', isValidIssuer);
    if (!isValidDocumentStatus) {
      return 'Document status is invalid!';
    }

    if (!isValidIntegrity) {
      return 'Document integrity has been breached!';
    }

    if (!isValidIssuer) {
      return 'Document issuer identity is invalid!';
    }

    return 'Document status is invalid!'; // Default fallback message
  };
  const handleReset = () => {
    setIsValidDoc(false);
    setUnwrappedData(null);
    setIsValidIssuer(false);
    setIsDocChecked(false);
    setIsValidDocumentStatus(false);
    setIsValidIntegrity(false);
    setIssuerLocation('');
    setTokenRegistry('');
  };

  const formattedAddress = (address: string) => {
    return address?.slice(0, 5) + '...' + address?.slice(-5);
  };

  return (
    <div
      className={cn(
        'flex w-full flex-col items-center gap-5 rounded-3xl border border-black bg-gradient-to-b from-white p-4 lg:gap-11 lg:px-5 lg:py-16',
        {
          'border-none bg-none': unwrappedData,
        }
      )}
    >
      {!unwrappedData && (
        <div className="flex w-full flex-col items-center justify-center gap-4 space-x-10 text-center text-sm lg:flex-row">
          <h2 className="font-serif text-3xl font-light text-blue-900">
            Verify your documents on
          </h2>
          <div className="flex items-center gap-4">
            {isConnected && (
              <button
                className="btn btn-sm rounded-lg"
                onClick={() => {
                  open({ view: 'Networks' });
                }}
              >
                Switch Network
              </button>
            )}
            <button
              className={clsx(
                'btn btn-primary btn-sm rounded-lg px-5',
                isConnected ? 'btn-outline btn-info' : ''
              )}
              onClick={() => open()}
            >
              {isConnected ? formattedAddress(address!) : 'Connect Wallet'}
            </button>
          </div>
        </div>
      )}

      {unwrappedData && isValidDoc && (
        <ValidDocument
          issuer={issuerLocation}
          isValidDocStatus={isValidDocumentStatus}
          isValidIntegrity={isValidIntegrity}
          isValidIssuer={isValidIssuer}
        />
      )}

      {tokenRegistry && unwrappedData && (
        <DocInfo
          signer={signer!}
          provider={providerRef.current!}
          currentChain={currentChain!}
          tokenRegistryAddress={tokenRegistry}
          walletAddress={address?.toLocaleLowerCase()!}
          tokenId={tokenId}
          document={encryptedData}
        />
      )}

      {unwrappedData && (
        <div className="ml-auto flex items-center justify-end">
          <button
            className="rounded-2xl bg-blue-900 px-3 py-2 text-xl text-white hover:bg-blue-950 lg:px-5 lg:py-4 lg:text-3xl"
            onClick={handleReset}
          >
            Upload Another file
          </button>
        </div>
      )}
      {(!unwrappedData || !isValidDoc) && (
        <div
          className={clsx(
            'flex w-full flex-col gap-20 lg:flex-row',
            !isConnected && 'pointer-events-none'
          )}
        >
          <div className="self-start rounded-full bg-gradient-purple-blue p-16">
            <img
              src={cloudUploadImg}
              width={500}
              height={500}
              alt="Cloud Upload"
            />
          </div>
          <div
            {...getRootProps()}
            className="flex flex-1 flex-col items-center gap-24 self-stretch text-center"
          >
            <div className="relative flex h-[270px] w-full max-w-[380px] flex-col items-center justify-center rounded-[50%] border border-[#3D2B91] bg-white">
              <img
                src={checkClipboard}
                height={200}
                width={200}
                alt="clipboard with a checkmark in middle"
                className="absolute bottom-1/2"
              />
              <span className="translate-y-full text-center text-sm lg:text-base">
                Drag the document over to see an example <br /> of Blockpeer
                verify document feature
              </span>
            </div>
            {!isValidDoc && isDocChecked && (
              <InvalidDocument msg={getDocumentErrorMessage()} />
            )}
            {loading ? (
              <Loader />
            ) : (
              <>
                {/* <CloudArrowUpIcon className="w-10 h-10" /> */}
                <input {...getInputProps()} />
              </>
            )}
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <div className="flex flex-col items-center gap-6 self-stretch text-[27px] font-light">
                {!isConnected && (
                  <p className="m-4 text-xl font-semibold text-error">
                    Please connect your wallet first!
                  </p>
                )}
                <p className="text-center">
                  Drop your files to verify its contents
                </p>
                <p>Or</p>
                <button className="rounded-2xl border border-black bg-gradient-dark-blue p-4 text-[27px] font-semibold text-white">
                  Select Documents
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      {unwrappedData && (
        <div className="w-full">
          <IframeApp documents={[{ name: '', document: unwrappedData }]} />
        </div>
      )}
    </div>
  );
};

export default FileReaderComponent;
