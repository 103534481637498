import React from 'react';

const Modal = ({ title, modalWidth = 'w-11/12', children }: any) => {
  return (
    <dialog id="my_modal_1" className="modal">
      <div className={`modal-box max-w-7xl ${modalWidth}`}>
        <form method="dialog">
          {/* if there is a button in form, it will close the modal */}
          <button className="btn btn-circle btn-ghost btn-sm absolute right-2 top-2">
            ✕
          </button>
        </form>
        <h3 className="text-lg font-bold">{title}</h3>
        {children}
        <div className="modal-action flex items-center justify-center">
          <form method="dialog">
            <button className="btn btn-wide">Close</button>
          </form>
        </div>
      </div>
    </dialog>
  );
};

export default Modal;
