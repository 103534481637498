import { useState, useEffect, useCallback } from 'react';
import { useTokenRegistryContract } from '../useTokenRegistryContract';
import { EndorsementChain, TransferBaseEvent } from '../../../types/index';
import { mergeTransfers } from './helpers';
import { getEndorsementChain } from './retrieveEndorsementChain';
import { retrieveTitleEscrowAddressOnFactory } from '../useTitleEscrowContract';
import { Signer, providers } from 'ethers';
import { TokenRegistryVersion } from '../../../components/DocInfo';
import { fetchEscrowTransfersV5 } from './fetchEscrowTransfer';
import { decrypt } from '@trustvc/trustvc';
import { getErrorMessage } from '../../../utils/errorHandling';

export const useEndorsementChain = (
  tokenRegistryAddress: string,
  tokenId: string,
  providerOrSigner: Signer,
  provider: providers.Provider,
  tokenRegistryVersion: string,
  keyId?: string
): {
  endorsementChain?: EndorsementChain;
  pending: boolean;
  error: string;
} => {
  const [pending, setPending] = useState(false);
  const [error, setError] = useState('');
  const [endorsementChain, setEndorsementChain] = useState<EndorsementChain>();
  const { tokenRegistry } = useTokenRegistryContract(
    tokenRegistryAddress,
    providerOrSigner
  );

  /*
    retrieve transactions from token registry and title escrow events
    merge, sort and provide history of events
  */
  const fetchEndorsementChain = useCallback(async () => {
    if (!tokenRegistry || !provider || !providerOrSigner) return;
    //console.log('fetching endorsement chain...')
    setEndorsementChain(undefined);
    setPending(true);
    setError('');
    try {
      let transferEvents: TransferBaseEvent[] = [];
      if (tokenRegistryVersion === TokenRegistryVersion.V5) {
        const titleEscrowAddress = await retrieveTitleEscrowAddressOnFactory(
          tokenRegistry,
          tokenId,
          providerOrSigner
        );
        const titleEscrowLogs = await fetchEscrowTransfersV5(
          providerOrSigner as any,
          titleEscrowAddress
        );
        transferEvents = mergeTransfers(titleEscrowLogs);
      } else {
        throw new Error('"Only Token Registry V5 is supported"');
      }

      const retrievedEndorsementChain = await getEndorsementChain(
        provider,
        transferEvents
      );

      //console.log('retrievedEndorsementChain: ', retrievedEndorsementChain);
      const decryptedEndorsementChain = retrievedEndorsementChain.map(event => {
        const remark = event?.remark?.slice(2)
          ? decrypt(event.remark.slice(2), keyId!)
          : '';
        return {
          ...event,
          remark: remark,
        };
      })

      //console.log('decryptedEndorsementChain: ', decryptedEndorsementChain);
      setEndorsementChain(decryptedEndorsementChain);
    } catch (e: unknown) {
      setError(getErrorMessage(e));
    }
    setPending(false);
  }, [
    provider,
    providerOrSigner,
    tokenId,
    tokenRegistry,
    tokenRegistryVersion,
    keyId,
  ]);

  useEffect(() => {
    fetchEndorsementChain();
  }, [fetchEndorsementChain, providerOrSigner]);

  return { endorsementChain, pending, error };
};
