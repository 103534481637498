import {
  FrameActions,
  FrameConnector,
  HostActionsHandler,
} from '@govtechsg/decentralized-renderer-react-components';
import {
  ArrowDownTrayIcon,
  PrinterIcon,
  QrCodeIcon,
} from '@heroicons/react/24/outline';
import React, { useCallback, useEffect, useState } from 'react';
import { saveAs } from 'file-saver';

interface AppProps {
  documents: {
    name: string;
    document: any;
  }[];
}

export const IframeApp: React.FunctionComponent<AppProps> = ({
  documents,
}): React.ReactElement => {
  const [toFrame, setToFrame] = useState<HostActionsHandler>();
  const [height, setHeight] = useState(0);
  const [templates, setTemplates] = useState<{ id: string; label: string }[]>(
    []
  );
  const [document, setDocument] = useState<{ name: string; document: any }>();
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');
  const fn = useCallback((toFrame: HostActionsHandler) => {
    // wrap into a function otherwise toFrame function will be executed
    setToFrame(() => toFrame);
  }, []);

  const fromFrame = (action: FrameActions): void => {
    if (action.type === 'UPDATE_HEIGHT') {
      setHeight(action.payload);
    }
    if (action.type === 'UPDATE_TEMPLATES') {
      setTemplates(action.payload);
      setSelectedTemplate(action.payload[0].id);
    }
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.renderDocument = document => {
    if (toFrame && document) {
      toFrame({
        type: 'RENDER_DOCUMENT',
        payload: {
          document,
        },
      });
    }
  };

  useEffect(() => {
    if (toFrame && document) {
      toFrame({
        type: 'RENDER_DOCUMENT',
        payload: {
          document: document.document,
        },
      });
    }
  }, [toFrame, document]);
  useEffect(() => {
    if (toFrame && selectedTemplate) {
      toFrame({
        type: 'SELECT_TEMPLATE',
        payload: selectedTemplate,
      });
    }
  }, [selectedTemplate, toFrame]);

  useEffect(() => {
    setDocument(documents[0]);
  }, [documents]);

  const handleDownload = () => {
    const blob = new Blob([JSON.stringify(documents[0], null, 2)], {
      type: 'text/json;charset=utf-8',
    });
    saveAs(blob, `Bill-Of-Lading.tt`);
  };

  return (
    <div className="my-4">
      <div className="mb-10 flex w-full items-center justify-end gap-4 text-center">
        <button
          className="rounded-lg px-2 py-1 text-blue-900 hover:border-black hover:bg-blue-900 hover:text-white"
          /* onClick={() => {
            if (toFrame) {
              toFrame({
                type: "PRINT",
              });
            }
          }} */
        >
          <QrCodeIcon className="w-10 lg:w-20" />
        </button>
        <button
          className="rounded-lg px-2 py-1 text-blue-900 hover:border-black hover:bg-blue-900 hover:text-white"
          onClick={() => {
            if (toFrame) {
              toFrame({
                type: 'PRINT',
              });
            }
          }}
        >
          <PrinterIcon className="w-10 lg:w-20" />
        </button>
        <button
          className="rounded-lg px-2 py-1 text-blue-900 hover:border-black hover:bg-blue-900 hover:text-white"
          onClick={handleDownload}
        >
          <ArrowDownTrayIcon className="w-10 lg:w-20" />
        </button>
      </div>
      <div className="container mx-auto rounded-lg bg-neutral-50 p-4 shadow shadow-neutral-800 lg:px-10 lg:py-20">
        <div className="flex flex-wrap">
          <main className="w-full">
            <ul>
              {templates.map(template => (
                <li
                  key={template.id}
                  className={`inline-block border border-b-0 p-2 ${selectedTemplate === template.id ? 'text-sky-500' : ''}`}
                  onClick={() => setSelectedTemplate(template.id)}
                >
                  <a>{template.label}</a>
                </li>
              ))}
            </ul>
            <FrameConnector
              source="https://blkp-doc-rendr.netlify.app/"
              dispatch={fromFrame}
              onConnected={fn}
              className={`w-full ${height !== 0 ? 'border' : ''}`}
              style={{ height: `${height}px` }}
            />
          </main>
        </div>
      </div>
    </div>
  );
};
