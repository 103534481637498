import { ShieldExclamationIcon } from '@heroicons/react/24/solid';
import { ReactComponent as VerifySVG } from './shared/assets/verify.svg';

type Props = {
  isValidDocStatus: boolean;
  isValidIntegrity: boolean;
  isValidIssuer: boolean;
  issuer: string;
};
const ValidDocument = ({
  issuer,
  isValidDocStatus,
  isValidIntegrity,
  isValidIssuer,
}: Props) => {
  return (
    <div className="flex w-full flex-col rounded-lg bg-neutral-50 p-4 shadow shadow-neutral-500 lg:px-10 lg:py-20">
      <div className="mb-10 flex w-full flex-col items-center justify-between gap-4 lg:mb-20 lg:flex-row">
        <p className="text-center font-serif text-3xl font-bold md:text-5xl lg:text-left">
          Issued by{' '}
          <span className="font-bold capitalize text-[#1435E3]">{issuer}</span>
        </p>
      </div>

      <div className="mt-3 flex w-full flex-col items-start justify-evenly gap-2 font-serif font-bold text-black md:flex-row md:items-center md:gap-8">
        <div className="flex items-center gap-2 text-sm">
          <div>
            {isValidDocStatus ? (
              <VerifySVG className="text-green-500" />
            ) : (
              <ShieldExclamationIcon className="w-6 text-red-500" />
            )}
          </div>
          <p>Document has been issued</p>
        </div>
        <div className="flex items-center gap-2 text-sm">
          <div>
            {isValidIssuer ? (
              <VerifySVG className="text-green-500" />
            ) : (
              <ShieldExclamationIcon className="w-6 text-red-500" />
            )}
          </div>

          <p>Document issuer has been identified</p>
        </div>
        <div className="flex items-center gap-2 text-sm">
          <div>
            {isValidIntegrity ? (
              <VerifySVG className="text-green-500" />
            ) : (
              <ShieldExclamationIcon className="w-6 text-red-500" />
            )}
          </div>

          <p>Document has not been tampered with</p>
        </div>
      </div>
      <div className="mt-5 hidden items-center gap-4">
        <p className="rounded-lg border border-orange-500 bg-gray-100 p-1 px-4 text-orange-500">
          Transferable
        </p>
        <p className="rounded-lg border border-orange-500 bg-gray-100 p-1 px-4 text-orange-500">
          Negotiable
        </p>
      </div>
    </div>
  );
};

export default ValidDocument;
