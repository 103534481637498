import { utils } from '@govtechsg/open-attestation';
import { WrappedDocument, OpenAttestationDocument } from '@trustvc/trustvc';
import {
  isSignedWrappedV2Document,
  isSignedWrappedV3Document,
} from '@trustvc/trustvc';

export const getDocumentData = (document: any): any => {
  return utils.isRawV3Document(document) || utils.getData(document)
    ? document.credentialSubject
    : document;
};
export const getOpenAttestationData = (
  wrappedDocument: WrappedDocument<OpenAttestationDocument>
): OpenAttestationDocument => {
  if (
    isSignedWrappedV2Document(wrappedDocument) ||
    isSignedWrappedV3Document(wrappedDocument)
  ) {
    return wrappedDocument as any;
  }
  return getDocumentData(wrappedDocument);
};
export const getKeyId = (
  wrappedDocument: WrappedDocument<OpenAttestationDocument>
): string | undefined => {
  return getOpenAttestationData(wrappedDocument)?.id;
};
