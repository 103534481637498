import './App.css';
import FileReaderComponent from './components/FileReaderComponent';
import Navbar from './components/shared/Navbar';
import Footer from './components/shared/Footer';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react';
import { chainList } from './constants/chainlist';
import JoinUs from './components/shared/JoinUs';

function App() {
  // 1. Get projectId at https://cloud.walletconnect.com
  const projectId = process.env.REACT_APP_WC_PROJECT_ID!;

  // 3. Create modal
  const metadata = {
    name: 'Blockpeer Verifier',
    description: 'Blockpeer finance document verifier',
    url: 'https://verify.blockpeer.finance', // origin must match your domain & subdomain
    icons: [
      'https://res.cloudinary.com/dligdeoxo/image/upload/v1709214271/ecj4bkieyxrm2r9eujyn.png',
    ],
  };

  createWeb3Modal({
    ethersConfig: defaultConfig({
      metadata,
      defaultChainId: 1,
      enableEIP6963: true,
      enableInjected: true,
      enableCoinbase: true,
      rpcUrl: '...', // used for the Coinbase SDK
    }),
    chains: [...chainList],
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
  });

  return (
    <div className="relative m-0 w-full bg-[#f5f8fe] pb-10 md:px-16">
      <div className="fixed left-0 top-0 z-50 w-full md:px-10">
        <Navbar />
      </div>
      <div className="h-6"></div>
      <main className="mt-4 px-5 pt-20 text-blue-900 md:mt-20 md:px-14 md:py-10">
        <FileReaderComponent />
      </main>
      <div className="px-5 md:px-14">
        <JoinUs />
        <Footer />
      </div>
    </div>
  );
}
export default App;

/*  trade trust supported chains
    CHAIN_ID["local"] = "1337";
    CHAIN_ID["mainnet"] = "1";
    CHAIN_ID["matic"] = "137";
    CHAIN_ID["maticmum"] = "80001";
    CHAIN_ID["sepolia"] = "11155111";
    CHAIN_ID["xdc"] = "50";
    CHAIN_ID["xdcapothem"] = "51";
    CHAIN_ID["stability"] = "101010";
    CHAIN_ID["hederamainnet"] = "295";
*/
