import { useIdentifierResolver } from '@govtechsg/address-identity-resolver';
import { format } from 'date-fns';
import React, { FunctionComponent } from 'react';
import { EndorsementChain } from '../../types/index';
import { EndorsementChainError } from './EndorsementChainError';
import { EndorsementChainLoading } from './EndorsementChainLoading';

interface IEndorsementChainLayout {
  endorsementChain?: EndorsementChain;
  error?: string;
  pending: boolean;
  providerDocumentationURL: string;
}
interface RemarkBlockProps {
  remark?: string;
}

enum ActionType {
  INITIAL = 'Document has been issued',
  NEW_OWNERS = 'Change Owners',
  ENDORSE = 'Endorse change of ownership',
  TRANSFER = 'Transfer holdership',
  REJECT_TRANSFER_HOLDER = 'Rejection of holdership',
  REJECT_TRANSFER_BENEFICIARY = 'Rejection of ownership',
  RETURNED_TO_ISSUER = 'ETR returned to issuer',
  RETURN_TO_ISSUER_REJECTED = 'Return of ETR rejected',
  RETURN_TO_ISSUER_ACCEPTED = 'ETR taken out of circulation', // burnt token
  TRANSFER_TO_WALLET = 'Transferred to wallet',
}

interface HistoryChain {
  action: ActionType;
  isNewBeneficiary: boolean;
  isNewHolder: boolean;
  beneficiary?: string;
  holder?: string;
  timestamp?: number;
  hash?: string;
  remark?: string;
}

interface AddressResolvedNameProps {
  address: string;
}
interface AddressBlockProps {
  title: string;
  address: string;
}

const AddressResolvedName: React.FunctionComponent<
  AddressResolvedNameProps
> = ({ address }) => {
  const { identityName } = useIdentifierResolver(address);
  return <>{identityName && <div className="mr-2"> {identityName}</div>}</>;
};

interface DetailsEntityProps
  extends Partial<AddressBlockProps>,
    Partial<RemarkBlockProps> {
  title: string;
}

const getHistoryChain = (endorsementChain?: EndorsementChain) => {
  const historyChain: HistoryChain[] = [];

  endorsementChain?.forEach((endorsementChainEvent: any) => {
    const beneficiary = endorsementChainEvent.owner;
    const holder = endorsementChainEvent.holder;
    const timestamp = endorsementChainEvent.timestamp;
    const hash = endorsementChainEvent.transactionHash;
    const remark = endorsementChainEvent?.remark;
    switch (endorsementChainEvent.type) {
      case 'TRANSFER_OWNERS':
        historyChain.push({
          action: ActionType.NEW_OWNERS,
          isNewBeneficiary: true,
          isNewHolder: true,
          beneficiary,
          holder,
          timestamp,
          hash,
        });
        break;
      case 'TRANSFER_BENEFICIARY':
        historyChain.push({
          action: ActionType.ENDORSE,
          isNewBeneficiary: true,
          isNewHolder: false,
          beneficiary,
          holder,
          timestamp,
          hash,
        });
        break;
      case 'TRANSFER_HOLDER':
        historyChain.push({
          action: ActionType.TRANSFER,
          isNewBeneficiary: false,
          isNewHolder: true,
          beneficiary,
          holder,
          timestamp,
          hash,
        });
        break;
      case 'RETURNED_TO_ISSUER':
        historyChain.push({
          action: ActionType.RETURNED_TO_ISSUER,
          isNewBeneficiary: true,
          isNewHolder: false,
          timestamp,
        });
        break;
      case 'RETURN_TO_ISSUER_ACCEPTED':
        historyChain.push({
          action: ActionType.RETURN_TO_ISSUER_ACCEPTED,
          isNewBeneficiary: false,
          isNewHolder: false,
          timestamp,
        });
        break;
      case 'RETURN_TO_ISSUER_REJECTED':
        historyChain.push({
          action: ActionType.RETURN_TO_ISSUER_REJECTED,
          isNewBeneficiary: true,
          isNewHolder: true,
          timestamp,
          beneficiary,
          holder: beneficiary,
          hash,
        });
        break;
      case 'INITIAL':
        historyChain.push({
          action: ActionType.INITIAL,
          isNewBeneficiary: true,
          isNewHolder: true,
          beneficiary,
          holder,
          timestamp,
          hash,
        });
        break;
      case 'REJECT_TRANSFER_HOLDER':
        historyChain.push({
          action: ActionType.REJECT_TRANSFER_HOLDER,
          isNewBeneficiary: false,
          isNewHolder: true,
          beneficiary,
          holder,
          timestamp,
          hash,
          remark,
        });
        break;
      case 'REJECT_TRANSFER_BENEFICIARY':
        historyChain.push({
          action: ActionType.REJECT_TRANSFER_BENEFICIARY,
          isNewBeneficiary: true,
          isNewHolder: false,
          beneficiary,
          holder,
          timestamp,
          hash,
          remark,
        });
        break;
      case 'REJECT_TRANSFER_OWNERS':
        historyChain.push({
          action: ActionType.REJECT_TRANSFER_HOLDER,
          isNewBeneficiary: false,
          isNewHolder: true,
          beneficiary,
          holder,
          timestamp,
          hash,
          remark,
        });
        historyChain.push({
          action: ActionType.REJECT_TRANSFER_BENEFICIARY,
          isNewBeneficiary: true,
          isNewHolder: false,
          beneficiary,
          holder,
          timestamp,
          hash,
          remark,
        });
        break;

      default:
        throw Error('eventType not matched');
    }
  });

  return historyChain;
};

const AddressBlock: React.FunctionComponent<AddressBlockProps> = ({
  title,
  address,
}) => {
  return (
    <div className="flex flex-col flex-nowrap gap-1 self-center overflow-hidden p-2">
      <h5 className="text-cloud-400 lg:hidden">{title}</h5>
      <h6 className="text-cerulean-500 break-all" data-testid="address-entity">
        {address}
      </h6>
      <AddressResolvedName address={address} />
    </div>
  );
};

const DetailsEntity: React.FunctionComponent<DetailsEntityProps> = ({
  title,
  address,
  remark,
}) => {
  return (
    <div className="w-full lg:w-1/3" data-testid={`row-event-${title}`}>
      <div className="flex flex-nowrap pr-8">
        <div className="relative shrink-0 lg:hidden" style={{ width: '40px' }}>
          <div className="absolute left-0 right-0 mx-auto h-full">
            <div className="border-cerulean-500 path absolute left-1/2 top-0 h-full border-l border-dashed" />
          </div>
        </div>
        <div className="pb-4 lg:pb-0">
          <h5 className="text-cloud-800 mr-2 lg:hidden">{title}</h5>
          <h6
            className="text-cerulean-500 break-all text-xs"
            data-testid="address-entity"
          >
            {address && <AddressBlock title={title} address={address} />}
            {remark && <RemarkBlock remark={remark} />}
          </h6>
        </div>
      </div>
    </div>
  );
};
const RemarkBlock: React.FunctionComponent<RemarkBlockProps> = ({ remark }) => {
  return (
    <div className="bg-cloud-100/30 text-cloud-400 mb-0 w-full break-all rounded-lg p-2 lg:mb-2">
      {remark}
    </div>
  );
};
const EndorsementChainData: React.FunctionComponent<any> = ({
  index,
  data,
}: {
  index: number;
  data: HistoryChain;
}) => {
  return (
    <div
      className="flex items-center w-full text-xs font-sans"
      data-testid={`row-event-${index}`}
    >
      
        <div className="flex flex-nowrap">
          <div
            className="relative shrink-0 lg:order-2"
            style={{ width: '40px' }}
          >
            <div className="absolute left-0 right-0 mx-auto h-full">
              <div className="border-cerulean-500 dot-path absolute left-1/2 h-full border-l border-dashed" />
              <div className="bg-cerulean-500 absolute left-1/2 top-1/2 h-3 w-3 -translate-x-1/2 -translate-y-1/2 rounded-full" />
            </div>
          </div>
          <div className="lg:order-1 lg:ml-auto md:w-48">
            <div className="py-6 lg:py-4 lg:text-left">
              <h4 className="text-cloud-800" data-testid="action-title">
                {data.action}
              </h4>
              {data.timestamp && (
                <h6 className="text-cloud-800">
                  {format(
                    new Date(data.timestamp ?? 0),
                    'do MMM yyyy, hh:mm aa'
                  )}
                </h6>
              )}
            </div>
          </div>
        </div>
      
      <DetailsEntity
        title="Owner"
        address={data.isNewBeneficiary ? data.beneficiary : ''}
      />
      <DetailsEntity
        title="Holder"
        address={data.isNewHolder ? data.holder : ''}
      />
      <DetailsEntity title="Remark" remark={data?.remark ?? ''} />
    </div>
  );
};

export const EndorsementChainLayout: FunctionComponent<
  IEndorsementChainLayout
> = ({ endorsementChain, error, pending, providerDocumentationURL }) => {
  const historyChain = getHistoryChain(endorsementChain);
  // console.log('endorsementChain: ', endorsementChain)
  // console.log('historyChain: ', historyChain)
  return (
    <div className="container my-8">
      <div className="my-4" data-testid="endorsement-chain-title">
        {/* <h3>Endorsement Chain</h3> */}
      </div>
      {!!error && (
        <div className="bg-red-100 py-3" data-testid="endorsement-chain-error">
          <p className="text-cloud-800 text-center">
            There might be some issue with your Remote Procedure Call (RPC).
            Click <a href={providerDocumentationURL}>here</a> to learn how to
            change your RPC Provider.
          </p>
        </div>
      )}
      <div className="rounded-xl bg-white px-3 py-8 shadow-xl lg:px-8">
        <div className="mb-8 hidden lg:block">
          <div className="text-cloud-800 flex">
            <h5 className="w-1/3">Action/Date</h5>
            <h5 className="w-1/3">Owner</h5>
            <h5 className="w-1/3">Holder</h5>
            <h5 className="w-1/4">Remark</h5>
          </div>
          <div className="border-t" />
        </div>

        {pending && !endorsementChain && !error && <EndorsementChainLoading />}
        {!pending && endorsementChain && !error && (
          <div className="endorsement-chain overflow-y-scroll">
            {[...historyChain].map((item, key) => (
              <EndorsementChainData index={key} data={item} key={key} />
            ))}
          </div>
        )}
        {!pending && !endorsementChain && error && (
          <EndorsementChainError error={error} />
        )}
      </div>
    </div>
  );
};
