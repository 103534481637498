import { v5Contracts } from '@trustvc/trustvc';
import { providers, Signer } from 'ethers';
import { useCallback, useEffect, useState } from 'react';
import { getLogger } from '../../utils/logger';
import { ContractFunctionState } from './useContractFunctionHook';
import { errorAlert } from '../../utils/alerts';

const { error: errorLogger } = getLogger('services:useburntoken');
type TradeTrustToken = v5Contracts.TradeTrustToken;
export const useBurnToken = (
  provider: providers.Provider | Signer | undefined,
  contractInstance?: TradeTrustToken,
  tokenId?: string
): {
  burnToken: (remark: string) => Promise<void>;
  state: ContractFunctionState;
  errorMessage?: string;
} => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [state, setState] = useState<ContractFunctionState>('UNINITIALIZED');

  const burnToken = async (remark: string = '0x'): Promise<void> => {
    setState('INITIALIZED');
    try {
      if (!provider) errorAlert('Selected network not supported!');
      if (!tokenId) throw new Error('Ownership data is not provided');
      if (!contractInstance?.getAddress())
        throw new Error('Token Registry Instance should have address');

      setState('PENDING_CONFIRMATION');
      await contractInstance.burn(tokenId, remark);
      setState('CONFIRMED');
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(JSON.stringify(error));
        errorLogger(error);
        setState('ERROR');
      }
    }
  };

  const reset = useCallback(() => {
    setState('UNINITIALIZED');
    setErrorMessage(undefined);
  }, []);

  // If any of the dependency is updated, should reset function
  useEffect(() => reset(), [contractInstance, provider, reset, tokenId]);

  return { burnToken, state, errorMessage };
};
