/* eslint-disable jsx-a11y/anchor-is-valid */
'use client';
import { TitleEscrow__factory } from '@tradetrust-tt/token-registry/contracts';
import { useEffect, useState } from 'react';
import { IChain } from '../types/chaintype';
import Modal from './Modal';
import { EndorsementChainLayout } from './EndorsementChain/EndorsementData';
import { errorAlert, successAlert } from '../utils/alerts';
import { isAddress } from 'ethers/lib/utils';
import Loader from './Loader';
import { Signer, providers } from 'ethers';
import { useTokenRegistryContract } from '../app/hooks/useTokenRegistryContract';
import { useTitleEscrowContract } from '../app/hooks/useTitleEscrowContract';
import { BurnAddress, InitialAddress } from '../configs';
import { useSupportsInterface } from '../app/hooks/useSupportsInterface';
import { useContractFunctionHook } from '../app/hooks/useContractFunctionHook';
import { useEndorsementChain } from '../app/hooks/useEndorsementChain';
import {
  v5SupportInterfaceIds,
  v4SupportInterfaceIds,
  v5RoleHash,
} from '@trustvc/trustvc';
import { useRestoreToken } from '../app/hooks/useRestoreToken';
import { getKeyId } from '../utils';
import { useBurnToken } from '../app/hooks/useBurnToken';
import { useTokenRegistryRole } from '../app/hooks/useTokenRegistryRole';

const ProviderDocumentationURL =
  'https://docs.tradetrust.io/docs/advanced/add-polygon-networks-to-metamask-wallet/';

declare global {
  interface Window {
    my_modal_1: HTMLFormElement;
  }
}
type IOwner = {
  beneficiary: string;
  holder: string;
  nominee: string;
  returned: boolean;
};
type Props = {
  signer: Signer;
  provider: providers.Provider;
  tokenRegistryAddress: string;
  currentChain: IChain;
  walletAddress: string;
  tokenId: string;
  document: any;
};

// TODO: HAN Move the constant value to token-registry repo
export const TitleEscrowInterface = {
  V4: v4SupportInterfaceIds.TitleEscrow,
  V5: v5SupportInterfaceIds.TitleEscrow,
};
export enum TokenRegistryVersion {
  V2 = 'V2',
  V4 = 'V4',
  V5 = 'V5',
}
const DocInfo = ({
  signer,
  provider,
  currentChain,
  tokenRegistryAddress,
  walletAddress,
  tokenId,
  document,
}: Props) => {
  const [action, setAction] = useState<
    | 'ChangeHolder'
    | 'EndorseChangeOwner'
    | 'EndorseTransfer'
    | 'NominateOwner'
    | 'ReturnToIssuer'
    | 'RejectTransferHolder'
    | 'RejectTransferBeneficiary'
    | 'RejectTransferOwners'
    | 'AcceptReturnToIssuer'
    | 'RejectReturnToIssuer'
    | null
  >(null);
  const [newHolder, setNewHolder] = useState('');
  const [newOwner, setNewOwner] = useState('');
  const [remarks, setRemarks] = useState('');
  const [titleEscrowAddress, setTitleEscrowAddress] = useState('');
  const [docOwners, setDocOwners] = useState<IOwner | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [disableTransfer, setDisableTransfer] = useState(false);
  const [isLoadingInitialData, setIsLoadingInitialData] = useState(false);
  const [modalContent, setModalContent] = useState<any>(null);
  const [modalTitle, setModalTitle] = useState('');
  const [modalWidth, setModalWidth] = useState('');

  const { tokenRegistry } = useTokenRegistryContract(
    tokenRegistryAddress,
    signer
  );
  const { titleEscrow, updateTitleEscrow, documentOwner } =
    useTitleEscrowContract(signer, tokenRegistry, tokenId);

  useEffect(() => {
    if (documentOwner) {
      setTitleEscrowAddress(documentOwner);
    }
  }, [documentOwner]);

  useEffect(() => {
    if (tokenRegistry) {
      setIsLoadingInitialData(true);
      // console.log('tokenRegistry', tokenRegistry);
      setTimeout(async () => {
        updateTitleEscrow();
      }, 2000);
    }
  }, [tokenRegistry, updateTitleEscrow]);

  const isReturnedToIssuer =
    documentOwner?.toLowerCase() === tokenRegistryAddress?.toLowerCase();
  const isTokenBurnt =
    documentOwner?.toLowerCase() === BurnAddress?.toLowerCase(); // check if the token belongs to burn address.

  // First check whether Contract is TitleEscrow
  const { isInterfaceType: isTitleEscrowV4 } = useSupportsInterface(
    titleEscrow,
    TitleEscrowInterface.V4
  );
  const { isInterfaceType: isTitleEscrowV5 } = useSupportsInterface(
    titleEscrow,
    TitleEscrowInterface.V5
  );
  const isTitleEscrow = isTitleEscrowV4 || isTitleEscrowV5;
  // Contract Read Functions
  const { call: getHolder, value: holder } = useContractFunctionHook(
    titleEscrow,
    'holder'
  );
  const { call: getBeneficiary, value: beneficiary } = useContractFunctionHook(
    titleEscrow,
    'beneficiary'
  );
  const { call: getApprovedBeneficiary, value: approvedBeneficiary } =
    useContractFunctionHook(titleEscrow, 'nominee');
  const { call: getPrevBeneficiary, value: prevBeneficiary } =
    useContractFunctionHook(titleEscrow, 'prevBeneficiary');
  const { call: getPrevHolder, value: prevHolder } = useContractFunctionHook(
    titleEscrow,
    'prevHolder'
  );
  const { call: getRemark, value: remark } = useContractFunctionHook(
    titleEscrow,
    'remark'
  );

  const { hasRole: hasAccepterRole } = useTokenRegistryRole({
    tokenRegistry,
    account: walletAddress,
    role: v5RoleHash.AccepterRole,
  });
  const { hasRole: hasRestorerRole } = useTokenRegistryRole({
    tokenRegistry,
    account: walletAddress,
    role: v5RoleHash.RestorerRole,
  });

  const canHandleRestore =
    isTitleEscrow &&
    hasAccepterRole &&
    isReturnedToIssuer &&
    documentOwner?.toLowerCase() === tokenRegistryAddress?.toLowerCase();

  const canHandleShred =
    isTitleEscrow &&
    hasRestorerRole &&
    isReturnedToIssuer &&
    documentOwner?.toLowerCase() === tokenRegistryAddress?.toLowerCase();

  const isActiveTitleEscrow = isTitleEscrow && !isReturnedToIssuer;

  const isHolder =
    isTitleEscrow &&
    walletAddress.toLowerCase() === holder?.toLocaleLowerCase();
  const isBeneficiary =
    isTitleEscrow && walletAddress.toLowerCase() === beneficiary?.toLowerCase();
  const canReturnToIssuer = isBeneficiary && isHolder && !isReturnedToIssuer;
  const canNominateBeneficiary =
    isActiveTitleEscrow && isBeneficiary && !isHolder;

  const hasNominee =
    !!approvedBeneficiary && approvedBeneficiary !== InitialAddress;
  const hasPreviousBeneficiary =
    !!prevBeneficiary && prevBeneficiary !== InitialAddress;
  const hasPreviousHolder = !!prevHolder && prevHolder !== InitialAddress;
  const canTransferBeneficiary = isActiveTitleEscrow && isHolder && hasNominee;
  const canTransferHolder = isActiveTitleEscrow && isHolder;

  const canTransferOwners = isActiveTitleEscrow && isHolder && isBeneficiary;
  const canRejectOwnerHolderTransfer =
    isActiveTitleEscrow &&
    isHolder &&
    isBeneficiary &&
    hasPreviousHolder &&
    hasPreviousBeneficiary;
  const canRejectHolderTransfer =
    isActiveTitleEscrow &&
    isHolder &&
    hasPreviousHolder &&
    !(isBeneficiary && hasPreviousBeneficiary);
  const canRejectOwnerTransfer =
    isActiveTitleEscrow &&
    isBeneficiary &&
    hasPreviousBeneficiary &&
    !(isHolder && hasPreviousHolder);

  const { restoreToken, state: restoreTokenState } = useRestoreToken(
    provider,
    tokenRegistry,
    tokenId
  );
  const { burnToken, state: burnTokenState } = useBurnToken(
    provider,
    tokenRegistry,
    tokenId
  );

  const canManage =
    canHandleShred ||
    canHandleRestore ||
    canReturnToIssuer ||
    canTransferHolder ||
    canTransferBeneficiary ||
    canNominateBeneficiary ||
    canTransferOwners ||
    canRejectOwnerHolderTransfer ||
    canRejectHolderTransfer ||
    canRejectOwnerTransfer;

  // Fetch all new information when title escrow is initialized or updated (due to actions)
  useEffect(() => {
    try {
      if (isTitleEscrow && titleEscrow) {
        // only fetch TitleEscrow info after we determine owner is a Title Escrow contract
        //promisify await for 1 sec
        getHolder();
        getBeneficiary();
        getApprovedBeneficiary();
        getPrevBeneficiary();
        getPrevHolder();
        getRemark();
        // console.log('holder:', holder);
        // console.log('beneficiary:', beneficiary);
        //console.log('approvedBeneficiary/nominee:', approvedBeneficiary);
        if (holder && beneficiary && approvedBeneficiary) {
          setDocOwners({
            beneficiary: beneficiary,
            holder: holder,
            nominee: approvedBeneficiary,
            returned: isReturnedToIssuer || isTokenBurnt,
          });
          //console.log('walletAddress: ', walletAddress);

          setIsLoadingInitialData(false);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  }, [
    getApprovedBeneficiary,
    getBeneficiary,
    getHolder,
    getPrevBeneficiary,
    getPrevHolder,
    getRemark,
    isTitleEscrow,
  ]);

  const getData = async () => {
    try {
      updateTitleEscrow();
    } catch (error) {
      console.error(error);
    }
  };
  const keyId = getKeyId(document);
  const endorsementChainProps = useEndorsementChain(
    tokenRegistryAddress,
    tokenId,
    signer,
    provider,
    isTitleEscrow ? TokenRegistryVersion.V5 : TokenRegistryVersion.V4,
    keyId
  );

  const nominateOwnership = async () => {
    try {
      if (!signer) {
        errorAlert('Signer not found!');
        return;
      }
      if (!newOwner || newOwner.trim() === '') {
        errorAlert('Please enter a owner address!');
        return;
      }
      if (!isAddress(newOwner)) {
        errorAlert('Please enter a valid ethereum address!');
        return;
      }
      setIsLoading(true);
      const connectedEscrow = TitleEscrow__factory.connect(
        titleEscrowAddress,
        signer as any
      );
      await connectedEscrow.nominate(newOwner, '0x');
      successAlert('Document has been nominated successfully!');
      const template = (
        <div className="mt-5">
          <p className="text-sm">
            Document has been nominated successfully. <br /> Please notify
            holder to execute transfer.
          </p>
        </div>
      );
      handleModalOpen('Nomination Success', template, 'w-1/3');
      setTimeout(() => {
        getData();
      }, 3000);
    } catch (error: any) {
      console.error(error);
      errorAlert(error.message);
    } finally {
      reset();
    }
  };
  const transferHolder = async () => {
    try {
      if (!signer) {
        errorAlert('Signer not found!');
        return;
      }
      if (!newHolder || newHolder.trim() === '') {
        errorAlert('Please enter a holder address!');
        return;
      }
      if (!isAddress(newHolder)) {
        errorAlert('Please enter a valid ethereum address!');
        return;
      }
      setIsLoading(true);
      //console.log("transferring holder...");
      const connectedEscrow = TitleEscrow__factory.connect(
        titleEscrowAddress,
        signer as any
      );
      await connectedEscrow.transferHolder(newHolder, '0x');
      successAlert('Holdership Transfered Successfully!');
      const template = (
        <div className="mt-5">
          <p className="text-sm">Current Holder</p>
          <p className="text-xl">{newHolder}</p>
        </div>
      );
      handleModalOpen('Transfer Holder Successfull', template, 'w-1/3');
      setTimeout(() => {
        getData();
      }, 3000);
    } catch (error: any) {
      console.error(error);
      errorAlert(error.message);
    } finally {
      reset();
    }
  };
  const transferOwners = async () => {
    if (!signer) {
      errorAlert('Signer not found!');
      return;
    }
    try {
      if (!newHolder || newHolder.trim() === '') {
        errorAlert('Please enter a holder address!');
        return;
      }
      if (!newOwner || newOwner.trim() === '') {
        errorAlert('Please enter a owner address!');
        return;
      }
      if (!isAddress(newOwner)) {
        errorAlert('Please enter a valid owner address!');
        return;
      }
      if (!isAddress(newHolder)) {
        errorAlert('Please enter a valid holder address!');
        return;
      }
      console.log('changing owners...')
      console.log('newHolder: ', newHolder)
      console.log('newOwner...',newOwner )
      setIsLoading(true);
      const connectedEscrow = TitleEscrow__factory.connect(
        titleEscrowAddress,
        signer as any
      );
      await connectedEscrow.transferOwners(newOwner, newHolder, '0x');
      successAlert('Endorse Transfered Successfully!');
      const template = (
        <div className="mt-5">
          <p className="text-sm">Current Owner</p>
          <p className="text-xl">{newOwner}</p>
          <p className="mt-5 text-sm">Current Holder</p>
          <p className="text-xl">{newHolder}</p>
        </div>
      );
      handleModalOpen(
        'Endorce Ownership / Holdership Transfered Successfully',
        template,
        'w-1/3'
      );
      setTimeout(() => {
        getData();
      }, 3000);
    } catch (error: any) {
      console.error(error);
      errorAlert(error.message);
    } finally {
      reset();
    }
  };
  const returnToIssuer = async () => {
    try {
      if (!signer) {
        errorAlert('Signer not found!');
        return;
      }
      setIsLoading(true);
      const connectedEscrow = TitleEscrow__factory.connect(
        titleEscrowAddress,
        signer as any
      );
      await connectedEscrow.returnToIssuer('0x');
      successAlert('Document Returned To Issuer Successfully!');
      const template = (
        <div className="mt-5">
          <p className="text-sm">Return ETR to Issuer</p>
          <p className="text-xl">
            This Bill of Lading has been returned to issuer, pending acceptance
            of issuer.
          </p>
        </div>
      );
      handleModalOpen(
        'Endorce Ownership / Holdership Transfered Successfully',
        template,
        'w-1/3'
      );
      setTimeout(() => {
        getData();
      }, 3000);
    } catch (error: any) {
      console.error(error);
      errorAlert(error.message);
    } finally {
      reset();
    }
  };
  const endorseChangeOwnership = async () => {
    try {
      if (!signer) {
        errorAlert('Signer not found!');
        return;
      }
      if (!docOwners?.nominee || docOwners.nominee.trim() === '') {
        errorAlert('Nominee not found!');
        return;
      }
      setIsLoading(true);
      const connectedEscrow = TitleEscrow__factory.connect(
        titleEscrowAddress,
        signer as any
      );
      await connectedEscrow.transferBeneficiary(docOwners?.nominee!, '0x');
      successAlert('Beneficiary Transfered Successfully!');
      const template = (
        <div className="mt-5">
          <p className="text-sm">Current Beneficiary</p>
          <p className="text-xl">{docOwners?.nominee}</p>
        </div>
      );
      handleModalOpen(' Beneficiary Updated Successfully', template, 'w-1/3');
      setTimeout(() => {
        getData();
      }, 3000);
    } catch (error: any) {
      console.error(error);
      errorAlert(error.message);
    } finally {
      reset();
    }
  };
  const rejectTransferHolder = async () => {
    try {
      if (!signer) {
        errorAlert('Signer not found!');
        return;
      }
      setIsLoading(true);
      const connectedEscrow = TitleEscrow__factory.connect(
        titleEscrowAddress,
        signer as any
      );
      const remarks = "0x"
      await connectedEscrow.rejectTransferHolder(remarks);
      successAlert('Holder Transfer Rejected Successfully!');
      setTimeout(() => {
        getData();
      }, 3000);
    } catch (error: any) {
      console.error(error);
      errorAlert(error.message);
    } finally {
      reset();
    }
  };
  const rejectTransferBeneficiary = async () => {
    try {
      if (!signer) {
        errorAlert('Signer not found!');
        return;
      }
      setIsLoading(true);
      const connectedEscrow = TitleEscrow__factory.connect(
        titleEscrowAddress,
        signer as any
      );

      await connectedEscrow.rejectTransferBeneficiary('0x');
      successAlert('Beneficiary Transfer Rejected Successfully!');
      setTimeout(() => {
        getData();
      }, 3000);
    } catch (error: any) {
      console.error(error);
      errorAlert(error.message);
    } finally {
      reset();
    }
  };
  const rejectTransferOwners = async () => {
    try {
      if (!signer) {
        errorAlert('Signer not found!');
        return;
      }
      setIsLoading(true);
      const connectedEscrow = TitleEscrow__factory.connect(
        titleEscrowAddress,
        signer as any
      );

      await connectedEscrow.rejectTransferOwners('0x');
      successAlert('Owners Transfer Rejected Successfully!');
      setTimeout(() => {
        getData();
      }, 3000);
    } catch (error: any) {
      console.error(error);
      errorAlert(error.message);
    } finally {
      reset();
    }
  };
  const handleAcceptReturnToIssuer = async () => {
    try {
      const remarks = '0x';
      const result = await burnToken(remarks);
      console.log(result);
    } catch (error) {
      console.error(error);
    }
  }
  const handleRejectReturnToIssuer = async () => {
    try {
      const remarks = '0x';
      const result = await restoreToken(remarks);
      console.log(result);
    } catch (error) {
      console.error(error);
    }
  }

  const handleEndorseChangeOwner = () => {
    setAction('EndorseChangeOwner');
  };
  const handleNominateOwner = () => {
    setAction('NominateOwner');
  };
  const handleTransferOwner = () => {
    setAction('EndorseTransfer');
  };
  const handleTransferHolder = () => {
    setAction('ChangeHolder');
  };
  const handleRejectHolder = () => {
    setAction('RejectTransferHolder');
  };
  const handleRejectBeneficiary = () => {
    setAction('RejectTransferBeneficiary');
  };
  const handleRejectOwners = () => {
    setAction('RejectTransferOwners');
  };
  const handleReturnToIssuer = () => {
    setAction('ReturnToIssuer');
  };
  const handleAcceptETRIssuer = () => {
    setAction('AcceptReturnToIssuer');
  };
  const handleRejectETRIssuer = () => {
    setAction('RejectReturnToIssuer');
  };
  const handleTransfer = () => {
    if (action === 'ChangeHolder') {
      transferHolder();
    } else if (action === 'EndorseTransfer') {
      endorseChangeOwnership();
    } else if (action === 'EndorseChangeOwner') {
      transferOwners();
    } else if (action === 'NominateOwner') {
      nominateOwnership();
    } else if (action === 'ReturnToIssuer') {
      returnToIssuer();
    } else if (action === 'RejectTransferHolder') {
      rejectTransferHolder();
    } else if (action === 'RejectTransferBeneficiary') {
      rejectTransferBeneficiary();
    } else if (action === 'RejectTransferOwners') {
      rejectTransferOwners();
    } else if (action === 'AcceptReturnToIssuer') {
      handleAcceptReturnToIssuer();
    } else if (action === 'RejectReturnToIssuer') {
      handleRejectReturnToIssuer();
    }
  };
  const handleModalOpen = (
    title: string,
    content: any,
    modalWidth: string = 'w-11/12'
  ) => {
    setModalTitle(title);
    setModalContent(content);
    setModalWidth(modalWidth);
    (
      window?.document?.getElementById('my_modal_1') as HTMLDialogElement
    )?.showModal();
  };

  const reset = () => {
    setNewHolder('');
    setNewOwner('');
    setRemarks('');
    setIsLoading(false);
    setAction(null);
  };
  useEffect(() => {
    if (
      action === 'ChangeHolder' &&
      (newHolder.trim() === '' ||
        !isAddress(newHolder) ||
        newHolder === docOwners?.holder)
    ) {
      setDisableTransfer(true);
    } else if (
      action === 'EndorseTransfer' &&
      (newHolder.trim() === '' ||
        newOwner.trim() === '' ||
        !isAddress(newHolder) ||
        !isAddress(newOwner) ||
        newHolder === docOwners?.holder)
    ) {
      setDisableTransfer(true);
    } else if (
      action === 'NominateOwner' &&
      (newOwner.trim() === '' || !isAddress(newOwner))
    ) {
      setDisableTransfer(true);
    } else {
      setDisableTransfer(false);
    }
  }, [action, newOwner, newHolder]);


  return (
    <>
      <Modal title={modalTitle} modalWidth={modalWidth}>
        {modalContent}
      </Modal>

      <div className="my-10 flex w-full flex-col items-start justify-between gap-5 rounded-lg bg-neutral-50 px-10 py-20 shadow shadow-neutral-500 md:flex-row">
        {/* Left side content */}
        <div className="flex flex-col items-start gap-3 w-1/2">
          <p className="text-xl font-bold text-gray-800">Nft Information:</p>
          <a
            target="_blank"
            className="link-hover link font-bold text-blue-500"
            href={
              currentChain?.explorerUrl + `/address/` + tokenRegistryAddress
            }
            rel="noreferrer"
          >
            View NFT Registry
          </a>
          <a
            className="link-hover link font-bold text-blue-500"
            onClick={() =>
              handleModalOpen(
                'Endorsement Chain',
                <EndorsementChainLayout
                  {...endorsementChainProps}
                  providerDocumentationURL={ProviderDocumentationURL}
                />
              )
            }
          >
            View Endorsement Chain
          </a>
        </div>

        <div className='flex flex-col items-center w-full'>
        {!isReturnedToIssuer && !isTokenBurnt && isTitleEscrow && (
          <div className="w-full">
            <div className="mb-5 flex items-center gap-5">
              <div className="flex flex-col items-start gap-3">
                {isLoadingInitialData ? (
                  <div className="flex w-96 flex-col gap-4">
                    <div className="skeleton h-6 w-full"></div>
                    <div className="skeleton h-6 w-full"></div>
                  </div>
                ) : (
                  <>
                    <p className="text-xl font-bold text-gray-500">
                      {action === 'EndorseChangeOwner' && docOwners?.nominee
                        ? 'Nominee:'
                        : 'Owner:'}
                    </p>
                    <a
                      target="_blank"
                      href={
                        currentChain?.explorerUrl +
                        `/address/` +
                        (action === 'EndorseChangeOwner' && docOwners?.nominee
                          ? docOwners?.nominee
                          : docOwners?.beneficiary)
                      }
                      className="link-hover link break-all font-bold text-blue-500"
                      rel="noreferrer"
                    >
                      {action === 'EndorseChangeOwner' && docOwners?.nominee
                        ? docOwners?.nominee
                        : docOwners?.beneficiary}
                    </a>
                  </>
                )}

                {action === 'EndorseTransfer' || action === 'NominateOwner' || action==='EndorseChangeOwner' ? (
                  <input
                    id="ownerAddress"
                    name="ownerAddress"
                    type="text"
                    className="input input-bordered w-full"
                    placeholder="Enter New Owner's address"
                    value={newOwner}
                    onChange={e => setNewOwner(e.target.value)}
                  />
                ) : null}
              </div>
              <div className="flex flex-col items-start gap-3">
                {isLoadingInitialData ? (
                  <div className="flex w-96 flex-col gap-4">
                    <div className="skeleton h-6 w-full"></div>
                    <div className="skeleton h-6 w-full"></div>
                  </div>
                ) : (
                  <>
                    <a className="text-xl font-bold text-gray-500">Holder: </a>
                    <a
                      target="_blank"
                      href={
                        currentChain?.explorerUrl +
                        `/address/` +
                        docOwners?.holder
                      }
                      className="link-hover link break-all font-bold text-blue-500"
                      rel="noreferrer"
                    >
                      {docOwners?.holder}
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
         
            <div className="flex items-center gap-5 w-full justify-between">
              {(action === 'ChangeHolder' || action === 'EndorseTransfer' || action === 'EndorseChangeOwner') && (
                <input
                  id="holderAddress"
                  name="holderAddress"
                  type="text"
                  value={newHolder}
                  className="input input-bordered w-full"
                  placeholder="Enter New Holder's address"
                  onChange={e => setNewHolder(e.target.value)}
                />
              )}
              {(action === 'AcceptReturnToIssuer' ||
                action === 'RejectReturnToIssuer' ||
                action === 'ReturnToIssuer' ||
                action === 'ChangeHolder' ||
                action === 'EndorseTransfer' ||
                action === 'RejectTransferBeneficiary' ||
                action === 'EndorseChangeOwner' ||
                action === 'NominateOwner' ||
                action === 'RejectTransferHolder' ||
                action === 'RejectTransferOwners') && (
                <input
                  id="remark"
                  name="remark"
                  type="text"
                  value={remarks}
                  className="input input-bordered w-full"
                  placeholder="Enter remarks here (max 120 characters)"
                  onChange={e => setRemarks(e.target.value)}
                />
              )}
            
          </div>
        </div>

        <div className='flex flex-col items-end justify-center gap-4 w-1/2'>
          {isReturnedToIssuer && (
            <h5 className="font-bold text-red-500 text-sm p-2 border-error border-2 hover:bg-red-500 hover:text-white rounded-md w-fit" data-testid="surrenderToIssuer">
            ETR returned to Issuer
          </h5>
          )}
          {isTokenBurnt && (
             <h5 className="font-bold text-red-500 text-sm p-2 border-error border-2 hover:bg-red-500 hover:text-white rounded-md w-fit" data-testid="surrenderToIssuer">
              Taken out of circulation
            </h5>
          )}
          {/* Right side button */}
          {canManage ? (
            <div className="flex items-center justify-center">
              {action ? (
                <div className="flex w-full flex-col gap-4">
                  
                  <button
                    className={`btn flex items-center gap-4 bg-green-500 text-white ${action.startsWith('reject') && 'bg-red-500'}`}
                    onClick={handleTransfer}
                    disabled={isLoading || disableTransfer}
                  >
                    {action === 'ReturnToIssuer' ? 'Return' : action.startsWith('reject') ? 'Reject' : action.startsWith('A') ? 'Accept' : (action.startsWith('T') || action.startsWith('C')) ? 'Transfer' : action.startsWith('N') ? 'Nominate':'Endorse'}
                    {isLoading && <Loader />}
                  </button>
                 
                  <button
                    onClick={() => reset()}
                    className="btn btn-outline btn-error px-10"
                    disabled={isLoading}
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <div className="dropdown">
                  <div
                    tabIndex={0}
                    role="button"
                    className="btn m-1 bg-blue-500 text-white"
                  >
                    Manage Assets
                  </div>
                  <ul
                    tabIndex={0}
                    className="menu dropdown-content z-[1] w-64 rounded-box bg-base-100 p-2 shadow"
                  >
                    {canTransferHolder && (
                      <li>
                        <a onClick={handleTransferHolder}>
                          Transfer Holdership
                        </a>
                      </li>
                    )}
                    {canTransferBeneficiary && (
                      <li>
                        <a onClick={handleTransferOwner}>
                          Endorse Change of Ownership
                        </a>
                      </li>
                    )}
                    {canNominateBeneficiary && (
                      <li>
                        <a onClick={handleNominateOwner}>
                          Nominate Change of Ownership
                        </a>
                      </li>
                    )}
                    {canReturnToIssuer && (
                      <li>
                        <a onClick={handleReturnToIssuer}>
                          Return ETR to Issuer
                        </a>
                      </li>
                    )}
                    {canHandleShred && (
                      <li>
                        <a onClick={handleAcceptETRIssuer}>Accept ETR Return</a>
                      </li>
                    )}
                    {canHandleRestore && (
                      <li>
                        <a onClick={handleRejectETRIssuer}>Reject ETR Return</a>
                      </li>
                    )}
                    {canTransferOwners && (
                      <li>
                        <a onClick={handleEndorseChangeOwner}>
                          {' '}
                          Endorse transfer of ownership
                        </a>
                      </li>
                    )}
                    {canRejectOwnerHolderTransfer && (
                      <li>
                        <a onClick={handleRejectOwners}>
                          Reject Ownership and Holdership
                        </a>
                      </li>
                    )}
                    {canRejectOwnerTransfer && (
                      <li>
                        <a onClick={handleRejectBeneficiary}>
                          Reject Ownership
                        </a>
                      </li>
                    )}
                    {canRejectHolderTransfer && (
                      <li>
                        <a onClick={handleRejectHolder}>Reject Holdership</a>
                      </li>
                    )}
                  </ul>
                </div>
              )}
            </div>
          ) : (
            <button className="btn">No Access</button>
          )}
        </div>
      </div>
    </>
  );
};

export default DocInfo;
