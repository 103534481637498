import React, { useState } from 'react';
import logo from './assets/logo.svg';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const genericHamburgerLine = `h-0.5 w-7 my-1 rounded-full bg-[#082f7a] transition ease transform duration-300`;

  return (
    <div className="z-50 flex h-full w-full flex-col items-center justify-center px-5 pt-2.5">
      <div className="relative inline-flex items-center justify-center gap-8 self-stretch rounded-xl bg-white px-6 py-[19px]">
        <div className="flex flex-1 items-start justify-start">
          <a href="https://www.blockpeer.finance/">
            {' '}
            <img src={logo} alt="logo" className="w-[10.5rem]" />
          </a>
        </div>
        {/* desktop menu */}
        <div className="hidden items-start justify-start gap-[36px] md:flex">
          <div className="nav-items">
            <a href="https://www.blockpeer.finance/blockpeer-trade">
              Blockpeer Trade
            </a>
          </div>
          <div className="nav-items">
            <a href="https://www.blockpeer.finance/blockpeer-cliq">
              Blockpeer Cliq
            </a>
          </div>
          <div className="nav-items">
            <a href="https://www.blockpeer.finance/blockpeer-books">
              Blockpeer Books
            </a>
          </div>
          <div className="nav-items">
            <a href="https://www.blockpeer.finance/pricing">Pricing</a>
          </div>
        </div>
        {/* mobile menu */}
        <div
          className={`absolute left-0 top-0 w-full transform rounded-lg bg-white p-4 transition-transform duration-500 md:hidden ${
            isOpen ? 'translate-y-24' : '-mt-10 -translate-y-full'
          }`}
        >
          <div className="mt-2 flex flex-col items-start justify-start gap-y-5 px-0">
            <div className="nav-items">
              <a href="https://www.blockpeer.finance/blockpeer-trade">
                Blockpeer Trade
              </a>
            </div>
            <div className="nav-items">
              <a href="https://www.blockpeer.finance/blockpeer-cliq">
                Blockpeer Cliq
              </a>
            </div>
            <div className="nav-items">
              <a href="https://www.blockpeer.finance/blockpeer-books">
                Blockpeer Books
              </a>
            </div>
            <div className="nav-items">
              <a href="https://www.blockpeer.finance/pricing">Pricing</a>
            </div>
            <a
              href="https://app.blockpeer.finance/register/en"
              className="btn w-full bg-[#082F7A] text-lg font-light text-white"
            >
              Start for Free
            </a>
          </div>
        </div>
        <div className="hidden flex-1 flex-col items-end justify-center md:flex">
          <a
            href="https://app.blockpeer.finance/register/en"
            className="rounded-md bg-[#082F7A] px-[26px] py-[9px] text-lg font-[300] text-white"
          >
            Start for Free
          </a>
        </div>
        <button
          className="group flex h-12 w-12 flex-col items-center justify-center rounded md:hidden"
          onClick={() => setIsOpen(!isOpen)}
        >
          <div
            className={`${genericHamburgerLine} ${
              isOpen
                ? 'translate-y-1 -rotate-45 opacity-50 group-hover:opacity-100'
                : 'opacity-50 group-hover:opacity-100'
            }`}
          />
          <div
            className={`${genericHamburgerLine} ${
              isOpen
                ? '-translate-y-1.5 rotate-45 opacity-50 group-hover:opacity-100'
                : 'opacity-50 group-hover:opacity-100'
            }`}
          />
        </button>
      </div>
    </div>
  );
};

export default Navbar;
