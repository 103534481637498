import React from 'react';
import dots from './assets/dots.svg';
import ellipses from './assets/ellipse.svg';

const JoinUs = () => {
  return (
    <div className="relative z-20 my-10 rounded-xl bg-slate-900 px-3 py-16">
      <img
        className="absolute -top-0 z-20 w-full md:left-56 md:w-2/3"
        src={dots}
        alt="dots"
      />
      <div className="absolute left-0 top-0 z-20 h-full w-full rounded-full">
        <img
          className="h-full w-full border-none object-cover"
          src={ellipses}
          alt="ellipses"
        />
      </div>
      <div className="relative mb-12 flex flex-col items-center px-3 md:space-y-28">
        <div className="relative z-20 flex flex-col items-center justify-start gap-10">
          <div className="flex flex-col items-center justify-start gap-6 md:h-36">
            <div className="text-center text-3xl font-bold leading-8 text-white md:text-5xl md:font-normal">
              Ready to Simplify Trade and Finance?
            </div>
            <div className="text-md text-center font-light text-white md:text-xl">
              Sign up for a free trial and discover how{' '}
              <span className="font-bold">BlockPeer</span> can help you
              seamlessly <br /> integrate{' '}
              <span className="font-bold">digital asset management</span>,{' '}
              <span className="font-bold">trade documentation</span>, and{' '}
              <span className="font-bold">financing</span> <br /> all in one
              platform.
            </div>
          </div>
        </div>
        <a
          href="https://app.blockpeer.finance/register/en"
          className="btn btn-wide z-50 mt-5 font-['Inter'] text-base font-normal leading-normal text-slate-900 hover:text-blue-600"
        >
          Book a demo
        </a>
      </div>
    </div>
  );
};

export default JoinUs;
