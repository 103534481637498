import { useRecoilState } from 'recoil';
import { selectedChainState } from '../atoms/Chain';
import { useEffect } from 'react';
import { chainList } from '../../constants/chainlist';
import { useWeb3ModalAccount } from '@web3modal/ethers5/react';

const useCurrentChain = () => {
  const [currentChain, setCurrentChain] = useRecoilState(selectedChainState);
  const { chainId, isConnected } = useWeb3ModalAccount();

  useEffect(() => {
    if (currentChain === null) {
      setCurrentChain(chainList[0]);
    } else {
      if (isConnected && chainId) {
        const chain = chainList.find(chain => chain.chainId === chainId);
        //console.log("chainId:- ", chainId);
        //console.log("chain: ", chain);
        if (chain) {
          setCurrentChain(chain!);
        }
      }
    }
  }, [isConnected, chainId]);
  return { currentChain, setCurrentChain };
};

export default useCurrentChain;
